<template>
    <b-card class="center_card" footer-class="my-footer">

        <b-card-title class="title-card-mdp mb-2">Mot de passe oublié ?</b-card-title>
        <div class="sub-title mb-3">Pour réinitialiser votre mot de passe, merci de renseigner l'email associé à votre compte Applicasol.</div>

      <b-row no-gutters>
        <b-col>
              <b-form-group
                  class="mt-2"
                  label-class="font-weight-bold"
                  label-size="lg"
                  label-align="left">
                <b-input-group >
                    <b-input-group-text variant="primary">
                      <i class="fas fa-user"></i>
                    </b-input-group-text>
                  <Field name="email" type="email" placeholder="Email" class="form-control" />
                </b-input-group>
                <ErrorMessage name="email" class="text-danger" />
              </b-form-group>
        </b-col>
      </b-row>
      <div class="valider">
        <button pill type="submit" @click.prevent = "sendMail" class="btn-valid m-2">Valider</button>
      </div>
      <div class="connect">
        <a><router-link to="login">Se connecter</router-link></a>
      </div>
      <template #footer>
    <div class="p-2">
      <span class="footer-card">Copyright © 2009-{{new Date().getFullYear()}} INRAE, Tous droits réservés</span>
    </div>
      </template>

    </b-card>
</template>

<script setup>
import http from '@/http.js'
import { ref } from 'vue';
import { useForm, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import router from "@/router";

const email = ref(null);

const schema = yup.object({
  email: yup.string().email('Le format de l\'email saisi est incorrect').required('Ce champ est obligatoire'),
});

const { handleSubmit } = useForm({
  validationSchema: schema
});

const sendMail = handleSubmit(async (values) => {
  try {
    const data = await http.verifExistUserByEmail(values.email);
    if (data) {
      email.value = await http.demandeMdp(values.email);
      await router.push({name: 'Login'})
    }
  } catch (error) {
    console.error("Erreur lors de l'envoi de l'email:", error);
  }
});
</script>

<style scoped>
</style>
