import axios from 'axios'
import config from './config';
import store from '@/store/index';

const token = store.state.token;
if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

class HttpClient {

// partie login
    verifExistUserByEmail($email){
        return axios.get(`${config.API_URL}demandemdp/verifbymail/${$email}`).then(response => response.data);
    }
    demandeMdp($email){
        return axios.post(`${config.API_URL}demandemdp/demande/?email=${$email}`).then(response => response.data);
    }
    reinitialiseMdp(reinitmdpData){
        return axios.post(`${config.API_URL}demandemdp/reinit/`, reinitmdpData).then(response => response.data);
    }

// partie applications
    getApplications() {
        return axios.get(`${config.API_URL}application/getApplications/`).then(response => response.data);
    }
    getEtendues(){
        return axios.get(`${config.API_URL}etendue/getEtendues/`).then(response => response.data);
    }
    getFinalites(){
        return axios.get(`${config.API_URL}finalite/getFinalites/`).then(response => response.data);
    }
    getResolutions(){
        return axios.get(`${config.API_URL}resolution/getResolutions/`).then(response => response.data);
    }
    getApplicationsMethode() {
        return axios.get(`${config.API_URL}application/getApplicationsMethode/`).then(response => response.data);
    }
    insertApplication(applicationData){
        return axios.post(`${config.API_URL}application/inserer/`, applicationData).then(response => response.data);
    }
    loadApplication($idApplication) {
        return axios.get(`${config.API_URL}application/afficher/${$idApplication}`).then(response => response.data);
    }
    updateApplication(applicationData) {
        return axios.post(`${config.API_URL}application/modifier/`, applicationData).then(response => response.data);
    }
    deleteApplication($idApplication) {
        return axios.post(`${config.API_URL}application/supprimer/${$idApplication}`).then(response => response.data);
    }

// partie localisation
    getRegions(){
        return axios.get(`${config.API_URL}region/getRegions/`).then(response => response.data);
    }
    getLocalisationByApp($idApplication){
        return axios.get(`${config.API_URL}localisation/getLocalisationsFrom/${$idApplication}`).then(response => response.data);
    }
    getLocalisationByNoRegion($noRegion){
        return axios.get(`${config.API_URL}localisation/getLocalisationsByNoRegion/${$noRegion}`).then(response => response.data);
    }
    insertLocalisationsFromApp($idApp,$noDepts) {
        return axios.post(`${config.API_URL}lAppLocalisation/inserer/?idApp=${$idApp}&noDepts=${$noDepts}`).then(response => response.data);
    }

// partie auteurs
    getAuteurs(){
        return axios.get(`${config.API_URL}auteur/getAuteurs/`).then(response => response.data);
    }
    getAuteursFromApp($idApplication){
        return axios.get(`${config.API_URL}auteur/getAuteursFrom/${$idApplication}`).then(response => response.data);
    }
    getAuteursFromRef($idRef){
        return axios.get(`${config.API_URL}auteur/getAuteursBy/${$idRef}`).then(response => response.data);
    }
    insertAuteur(auteurData){
        return axios.post(`${config.API_URL}auteur/inserer/`, auteurData).then(response => response.data);
    }
    loadAuteur($idAuteur) {
        return axios.get(`${config.API_URL}auteur/afficher/${$idAuteur}`).then(response => response.data);
    }
    updateAuteur(auteurData) {
        return axios.post(`${config.API_URL}auteur/modifier/`, auteurData).then(response => response.data);
    }
    deleteAuteur($idAuteur) {
        return axios.post(`${config.API_URL}auteur/supprimer/${$idAuteur}`).then(response => response.data);
    }
    insertAuteursFromApp($idApp,$idAuteur) {
        return axios.post(`${config.API_URL}lAppSource/inserer/?idApp=${$idApp}&idAuteur=${$idAuteur}`).then(response => response.data);
    }
    verifLAppSourceExist($idAuteur){
        return axios.get(`${config.API_URL}lAppSource/exist/${$idAuteur}`).then(response => response.data);
    }
    deleteAuteurFromApp($idApplication) {
        return axios.post(`${config.API_URL}lAppSource/supprimer/${$idApplication}`).then(response => response.data);
    }

// partie organismes
    getOrganismes(){
        return axios.get(`${config.API_URL}organisme/getOrganismes/`).then(response => response.data)
    }
    getOrganismesBySigle(){
        return axios.get(`${config.API_URL}organisme/getOrganismesBySigle/`).then(response => response.data)
    }
    getOrganismesDemandeurFromApp($idApplication){
        return axios.get(`${config.API_URL}organisme/getOrgDFrom/${$idApplication}`).then(response => response.data)
    }
    insertOrganismesDemandeurFromApp($idApp,$idOrgD) {
        return axios.post(`${config.API_URL}lAppOrgd/inserer/?idApp=${$idApp}&idOrgD=${$idOrgD}`).then(response => response.data);
    }
    deleteOrganismesDemandeurFromApp($idApplication) {
        return axios.post(`${config.API_URL}lAppOrgd/supprimer/${$idApplication}`).then(response => response.data);
    }
    verifLAppOrgExist($idOrg){
        return axios.get(`${config.API_URL}organisme/exist/${$idOrg}`).then(response => response.data);
    }
    getOrganismesConcepteurFromApp($idApplication){
        return axios.get(`${config.API_URL}organisme/getOrgCFrom/${$idApplication}`).then(response => response.data)
    }
    insertOrganismesConcepteurFromApp($idApp,$idOrgC) {
        return axios.post(`${config.API_URL}lAppOrgc/inserer/?idApp=${$idApp}&idOrgC=${$idOrgC}`).then(response => response.data);
    }
    deleteOrganismesConcepteurFromApp($idApplication) {
        return axios.post(`${config.API_URL}lAppOrgc/supprimer/${$idApplication}`).then(response => response.data);
    }
    getTypeOrgs(){
        return axios.get(`${config.API_URL}typeOrg/getTypeOrgs/`).then(response => response.data)
    }
    getLocalisations(){
        return axios.get(`${config.API_URL}localisation/getLocalisations/`).then(response => response.data)
    }
    insertOrganisme(organismeData){
        return axios.post(`${config.API_URL}organisme/inserer/`, organismeData).then(response => response.data);
    }
    loadOrganisme($idOrganisme) {
        return axios.get(`${config.API_URL}organisme/afficher/${$idOrganisme}`).then(response => response.data);
    }
    updateOrganisme(organismeData) {
        return axios.post(`${config.API_URL}organisme/modifier/`, organismeData).then(response => response.data);
    }
    deleteOrganisme($idOrganisme) {
        return axios.post(`${config.API_URL}organisme/supprimer/${$idOrganisme}`).then(response => response.data);
    }

// partie refBiblio
    getRefs(){
        return axios.get(`${config.API_URL}reference/getReferences/`).then(response => response.data)
    }
    getRefsFromApp($idApplication){
        return axios.get(`${config.API_URL}reference/getRefsFrom/${$idApplication}`).then(response => response.data);
    }
    getRefsFromEtape($idEtape){
        return axios.get(`${config.API_URL}reference/getRefsBy/${$idEtape}`).then(response => response.data);
    }
    insertRef(refBiblioData){
        return axios.post(`${config.API_URL}reference/inserer/`, refBiblioData).then(response => response.data);
    }
    loadRef($idRef) {
        return axios.get(`${config.API_URL}reference/afficher/${$idRef}`).then(response => response.data);
    }
    updateRef(refBiblioData) {
        return axios.post(`${config.API_URL}reference/modifier/`, refBiblioData).then(response => response.data);
    }
    deleteRef($idRef) {
        return axios.post(`${config.API_URL}reference/supprimer/${$idRef}`).then(response => response.data);
    }
    getTypeRefs(){
        return axios.get(`${config.API_URL}typeref/getRefs/`).then(response => response.data)
    }
    insertRefsFromApp($idApp,$idRef) {
        return axios.post(`${config.API_URL}lAppRef/inserer/?idApp=${$idApp}&idRef=${$idRef}`).then(response => response.data);
    }
    deleteRefsFromApp($idApplication) {
        return axios.post(`${config.API_URL}lAppRef/supprimer/${$idApplication}`).then(response => response.data);
    }
    verifLAppRefExist($idRef){
        return axios.get(`${config.API_URL}lAppRef/exist/${$idRef}`).then(response => response.data);
    }

// partie thematiques
    getThematiques(){
        return axios.get(`${config.API_URL}thematique/getThematiques/`).then(response => response.data)
    }
    getThematiquesFromApp($idApplication){
        return axios.get(`${config.API_URL}thematique/getThematiquesFrom/${$idApplication}`).then(response => response.data)
    }
    insertThematiquesFromApp($idApp,$idThem) {
        return axios.post(`${config.API_URL}lAppThem/inserer/?idApp=${$idApp}&idThem=${$idThem}`).then(response => response.data);
    }
    deleteThematiqueFromApp($idApplication) {
        return axios.post(`${config.API_URL}lAppThem/supprimer/${$idApplication}`).then(response => response.data);
    }

// partie methode
    getValidations(){
        return axios.get(`${config.API_URL}validationMeth/getValidationsMeth/`).then(response => response.data);
    }
    getFacilites(){
        return axios.get(`${config.API_URL}facilite/getFacilites/`).then(response => response.data);
    }
    getResolutionsMethode(){
        return axios.get(`${config.API_URL}resolutionMeth/getResolutionsMeth/`).then(response => response.data);
    }
    getOutils(){
        return axios.get(`${config.API_URL}outil/getOutils/`).then(response => response.data);
    }
    loadMethode($idApplication) {
        return axios.get(`${config.API_URL}methode/afficher/${$idApplication}`).then(response => response.data);
    }
    insertMethode(methodeData){
        return axios.post(`${config.API_URL}methode/inserer/`, methodeData).then(response => response.data);
    }
    updateMethode(methodeData) {
        return axios.post(`${config.API_URL}methode/modifier/`, methodeData).then(response => response.data);
    }
    getTypeMethTrait(){
        return axios.get(`${config.API_URL}typeMethTrait/getTypesMeth/`).then(response => response.data);
    }

    //partie Etapes de traitement
    insertEtape(etapeData){
        return axios.post(`${config.API_URL}etape/inserer/`, etapeData).then(response => response.data);
    }
    getEtapesByMethodeInApp($idApplication){
        return axios.get(`${config.API_URL}etape/getEtapesFrom/${$idApplication}`).then(response => response.data);
    }
    updateEtape(etapeData) {
        return axios.post(`${config.API_URL}etape/modifier/`, etapeData).then(response => response.data);
    }
    deleteEtape($idEtape){
        return axios.post(`${config.API_URL}etape/supprimer/${$idEtape}`).then(response => response.data);
    }
    loadEtape($idEtape) {
        return axios.get(`${config.API_URL}etape/afficher/${$idEtape}`).then(response => response.data);
    }
    insertEtapeFromMethode(lMethodeEtapeData) {
        return axios.post(`${config.API_URL}lMethEtape/inserer/`, lMethodeEtapeData).then(response => response.data);
    }
    insertRefsFromEtape($idEtape,$idRef) {
        return axios.post(`${config.API_URL}lMethTraitRef/inserer/?idEtape=${$idEtape}&idRef=${$idRef}`).then(response => response.data);
    }
    deleteRefsFromEtape($idEtape) {
        return axios.post(`${config.API_URL}lMethTraitRef/supprimer/${$idEtape}`).then(response => response.data);
    }
    verifLMethTraitRefExist($idRef){
        return axios.get(`${config.API_URL}lMethTraitRef/exist/${$idRef}`).then(response => response.data);
    }

    //partie Données
    getDonnees(){
        return axios.get(`${config.API_URL}donnees/getDonnees/`).then(response => response.data);
    }
    loadDonnees($idDonnee) {
        return axios.get(`${config.API_URL}donnees/afficher/${$idDonnee}`).then(response => response.data);
    }
    getDonneesFromEtape($idEtape){
        return axios.get(`${config.API_URL}donnees/getDonneesFrom/${$idEtape}`).then(response => response.data);
    }
    insertDonnees(donneesData){
        return axios.post(`${config.API_URL}donnees/inserer/`, donneesData).then(response => response.data);
    }
    updateDonnees(donneesData) {
        return axios.post(`${config.API_URL}donnees/modifier/`, donneesData).then(response => response.data);
    }
    deleteDonnee($idDonnee) {
        return axios.post(`${config.API_URL}donnees/supprimer/${$idDonnee}`).then(response => response.data);
    }
    insertDonneesFromEtape($idEtape,$idDonnee) {
        return axios.post(`${config.API_URL}lEtapeDonnee/inserer/?idEtape=${$idEtape}&idDonnee=${$idDonnee}`).then(response => response.data);
    }
    deleteDonneesFromEtape($idEtape) {
        return axios.post(`${config.API_URL}lEtapeDonnee/supprimer/${$idEtape}`).then(response => response.data);
    }
    verifLEtapeDonneeExist($idDonnee){
        return axios.get(`${config.API_URL}lEtapeDonnee/exist/${$idDonnee}`).then(response => response.data);
    }
}

export default new HttpClient() 
